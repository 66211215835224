import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { renderAppFabricWidget } from './appfabricDevelopmentKit';

import Button from 'components/Shared/Button';
import Spinner from 'components/Shared/Spinner/Spinner';
import { useSelector } from 'store/utils';

export interface FrictionlessLoginPartTwoProps {
  onCancelButtonClick: () => void;
  onComplete: () => void;
  onError: (error: Error) => void;
  onReady: (startedAt: Date) => void;
  email: string;
  sessionId: string;
}

type NestedObject = {
  [key: string]: string | boolean | number | NestedObject | ((...args: any[]) => void);
};

declare global {
  interface Window {
    AppFabric: {
      init: () => Promise<{
        renderWidget: (
          id: string,
          opts: {
            version: string;
            container: HTMLElement;
            props: NestedObject;
          }
        ) => Promise<void>;
      }>;
    };
  }
}

const FrictionlessLoginPartTwo: React.FC<FrictionlessLoginPartTwoProps> = ({
  onCancelButtonClick,
  onComplete,
  onError,
  onReady,
  email,
  sessionId,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const APPFABRIC_DEVELPMENT_KIT_SRC = useSelector(
    (state) => state.config.endpoints.appfabricDevelopmentKitJsSdk
  );
  useEffect(() => {
    renderIdentity();
  }, []);

  const renderIdentity = async () => {
    try {
      const identityWidgetLoadStart = new Date();
      const props = {
        email,
        sessionId,
        isRemediationOnly: false,
        onDone: async () => {
          onComplete();
        },
        onError: handleError,
        onReady: () => {
          onReady(identityWidgetLoadStart);
          setIsLoading(false);
        },
      };
      await renderAppFabricWidget(
        APPFABRIC_DEVELPMENT_KIT_SRC,
        'cpclient-webplugin/frictionless-login',
        containerRef.current as HTMLDivElement,
        props,
        () => {
          handleError(new Error('ADK failed to load'));
        }
      );
    } catch (error) {
      handleError(error as Error);
    }
  };

  const handleError = (error: Error) => {
    setError(true);
    setIsLoading(false);
    onError(error);
  };

  return (
    <>
      <div className={cn('container')}>
        <div className="loading-container">
          {isLoading && <Spinner />}
          {error && (
            <FormattedMessage
              id="PAYNOW_SOMETHING_WENT_WRONG"
              defaultMessage="Something went wrong"
            />
          )}
          <div ref={containerRef} style={{ display: isLoading || error ? 'none' : 'block' }} />
        </div>
        <Button buttonType="tertiary" onClick={onCancelButtonClick}>
          <FormattedMessage id="FRICTIONLESS_LOGIN_CANCEL" defaultMessage="Continue as guest" />
        </Button>
      </div>
      <style jsx>{`
        .container {
          min-height: 540px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .loading-container {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      `}</style>
    </>
  );
};

export default FrictionlessLoginPartTwo;
