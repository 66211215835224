import cn from 'classnames';
import React from 'react';

import RoundedModal from 'components/Core/Modal/RoundedModal';
import { borderRadius, breakpoints, colors, paddingSize, fontSize } from 'styles/cp';

export interface BottomSheetModalProps {
  hide(event: React.MouseEvent<HTMLSpanElement, MouseEvent>): void;
  header?: React.ReactNode;
  subHeader?: React.ReactNode;
  children?: React.ReactNode;
  isCloseVisible?: boolean;
}

/**
 * A modal window which behaves like Rounded modal on Desktop,
 * but is shown in the bottom of the screen on the mobile
 */
const BottomSheetModal: React.FC<BottomSheetModalProps> = ({
  hide,
  children,
  header,
  isCloseVisible,
  subHeader,
}) => {
  return (
    <>
      <div className="container">
        <RoundedModal
          hide={hide}
          header={header}
          className={cn('bottom-sheet-modal')}
          overlayClassName={cn('bottom-sheet-modal__overlay')}
          headerClassName={cn('bottom-sheet-modal__header')}
          isCloseVisible={isCloseVisible}
        >
          {subHeader && (
            <div className="bottom-sheet-modal__subheader">
              <span>{subHeader}</span>
            </div>
          )}
          {children}
        </RoundedModal>
      </div>
      <style jsx>{`
        @keyframes modalAppear {
          0% {
            transform: translateY(10vh); /* Desktop transform */
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
        .container :global(.bottom-sheet-modal) {
          animation: modalAppear 600ms cubic-bezier(0, 0, 0.25, 1) forwards;
          padding: ${paddingSize.lg};
          min-width: 446px;
          border-radius: ${borderRadius.default};
        }
        .container :global(.bottom-sheet-modal__header) {
          text-align: center;
          display: block;
          margin-top: 0;
          margin-bottom: 0;
          & > :global(span) {
            padding-top: 0;
            font-size: ${fontSize.ml};
          }
        }
        .container .bottom-sheet-modal__subheader {
          color: ${colors.gray02};
          padding: ${paddingSize.xxs} 0;
          margin-bottom: ${paddingSize.xs};
        }
        .container :global(.bottom-sheet-modal__overlay) {
          z-index: 1099;
        }
        @media screen and (max-width: ${breakpoints.sm}) {
          @keyframes modalAppear {
            0% {
              transform: translateY(100%); /* Mobile transform */
              opacity: 0;
            }
            100% {
              transform: translateY(0);
              opacity: 1;
            }
          }
          .container :global(.bottom-sheet-modal) {
            margin-top: auto;
            border-radius: ${borderRadius.mobile};
            padding: ${paddingSize.lg} ${paddingSize.sm};
            min-width: 0;
            width: 100vw;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
          .container :global(.bottom-sheet-modal__overlay) {
            padding-bottom: 0;
          }
        }
      `}</style>
    </>
  );
};

export default BottomSheetModal;
