import type { RootState } from 'store/store';

export type AuthSelectorState = Pick<RootState, 'auth'>;

export const authTicketSelector = (store: AuthSelectorState): string | undefined => {
  return store.auth.ticket;
};

export const isFrictionlessLoginEnabledSelector = (store: AuthSelectorState): boolean => {
  return store.auth.isFrictionlessLoginEnabled;
};

export const frictionlessLoginEmailSelector = (store: AuthSelectorState): string | undefined => {
  return store.auth.frictionlessLoginEmail;
};

export const frictionlessLoginSessionIdSelector = (
  store: AuthSelectorState
): string | undefined => {
  return store.auth.frictionlessLoginSessionId;
};
