import cn from 'classnames';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  cleanupAppFabricDevelopmentKit,
  loadAppFabricDevelopmentKit,
} from './appfabricDevelopmentKit';
import { useReportFrictionlessEvent } from './useFrictionlessLoginLogs';

import Paper from 'components/Core/Paper';
import Button from 'components/Shared/Button';
import SegmentIO from 'reporting/SegmentIO';
import { useSelector } from 'store/utils';
import { paddingSize, breakpoints, colors } from 'styles/cp';

export interface FrictionlessLoginPartOneProps {
  email: string;
  onContinueButtonClick: () => void;
  onCancelButtonClick: () => void;
  onNotMeButtonClick: () => void;
}

export const FrictionlessLoginPartOneHeader = (
  <FormattedMessage id="FRICTIONLESS_LOGIN_STEP_1_HEADER" defaultMessage="Let’s sign you back in" />
);
export const FrictionlessLoginPartOneSubHeader = (
  <FormattedMessage
    id="FRICTIONLESS_LOGIN_STEP_1_SUB_HEADER"
    defaultMessage="for easier, quicker secure checkout"
  />
);
const maskEmail = (email: string): string => {
  const [localPart, domain] = email.split('@');
  if (!localPart || !domain) return email; // Return original email if it's not valid

  if (localPart.length <= 2) {
    // If the local part is too short to mask, return it as is
    return email;
  }

  const maskedLocalPart = `${localPart[0]}••••••${localPart[localPart.length - 1]}`;
  return `${maskedLocalPart}@${domain}`;
};

const FrictionlessLoginPartOne: React.FC<FrictionlessLoginPartOneProps> = ({
  email,
  onNotMeButtonClick,
  onCancelButtonClick,
  onContinueButtonClick,
}) => {
  const APPFABRIC_DEVELPMENT_KIT_SRC = useSelector(
    (state) => state.config.endpoints.appfabricDevelopmentKitJsSdk
  );
  const frictionlessLogger = useReportFrictionlessEvent();
  useEffect(() => {
    // Preloading ADK in advance to reduce the time it takes to render the widget on the next step
    loadAppFabricDevelopmentKit(APPFABRIC_DEVELPMENT_KIT_SRC, () => {
      frictionlessLogger.log({
        logLevel: 'error',
        event: 'load',
        message: 'ADK failed to preload',
        extraParams: {
          source: 'onError',
        },
        error: new Error('ADK failed to preload'),
      });
    });
    frictionlessLogger.log({
      event: 'preload',
      message: 'frictionless-login-step-1-adk-preloaded',
    });
    SegmentIO.frictionlessLoginShow();
    return () => {
      cleanupAppFabricDevelopmentKit(APPFABRIC_DEVELPMENT_KIT_SRC);
    };
  }, []);

  return (
    <>
      <div>
        <Paper
          action={
            <Button buttonType="tertiary" role="link" onClick={onNotMeButtonClick}>
              <FormattedMessage
                id="FRICTIONLESS_LOGIN_STEP_1_SIGN_IN_AS_DIFFERENT_USER"
                defaultMessage="Not you?"
              />
            </Button>
          }
        >
          <FormattedMessage id="FRICTIONLESS_LOGIN_STEP_1_SIGN_IN_AS" defaultMessage="Sign in as" />{' '}
          {maskEmail(email)}
        </Paper>
        <div className={cn('body')}>
          <FormattedMessage
            id="FRICTIONLESS_LOGIN_STEP_1_BODY"
            defaultMessage="We’d like to send a security code to your phone"
          />
          .
          <p className={cn('body-copy')}>
            <FormattedMessage
              id="FRICTIONLESS_LOGIN_STEP_1_BODY_DISCLAIMER"
              defaultMessage='By selecting "Sure", you consent to receive a one-time automated text message from Intuit. Standard SMS rates may apply.'
            />
          </p>
        </div>
        <div className="buttons">
          <Button buttonType="primary" onClick={onContinueButtonClick}>
            <FormattedMessage id="FRICTIONLESS_LOGIN_STEP_1_SURE" defaultMessage="Sure" />
          </Button>
          <Button buttonType="tertiary" onClick={onCancelButtonClick}>
            <FormattedMessage id="FRICTIONLESS_LOGIN_CANCEL" defaultMessage="Continue as guest" />
          </Button>
        </div>
      </div>
      <style jsx>{`
        .body {
          padding: ${paddingSize.lg} 0 ${paddingSize.md} 0;
          font-weight: 700;
        }
        .body-copy {
          font-size: 12px;
          font-weight: 500;
          line-height: 14.34px;
          text-align: center;
          color: ${colors.gray02};
          margin-top: 4px;
          margin-bottom: 4px;
          max-width: 400px;
        }
        .buttons {
          display: flex;
          justify-content: center;
          gap: 8px;
        }
        @media screen and (max-width: ${breakpoints.sm}) {
          .body {
            padding: ${paddingSize.lg} 0;
          }
        }
      `}</style>
    </>
  );
};

export default FrictionlessLoginPartOne;
