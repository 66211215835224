import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { getLegalLinks } from 'shared/clientUtils';
import { colors, fontSize } from 'styles/cp';
import { DateFormat_Long } from 'types/constants';

interface payment {
  amount: number;
  paymentMethodType: string;
  isAutoPayOn?: boolean;
}

export interface PaymentDisclaimerProps {
  payment: payment;
  currency: string;
  dueDate: string;
}

const PaymentDisclaimer: React.FC<PaymentDisclaimerProps> = ({
  currency,
  payment: { amount, paymentMethodType, isAutoPayOn = false },
  dueDate = '',
}) => {
  const intl = useIntl();
  const displayAmount = intl.formatNumber(amount, { style: 'currency', currency });
  let paymentMethodTypeDisclaimer;

  const { privacyUrl, tosUrl, caToSUrl } = getLegalLinks(intl.locale);
  const paymentMethod =
    paymentMethodType === 'bank' || paymentMethodType === 'pbb' || paymentMethodType === 'eft'
      ? intl
          .formatMessage({
            id: 'PAYFLOW_PAYMENT_TYPE_BANK_ACCOUNT',
            defaultMessage: 'bank account',
          })
          .toLowerCase()
      : intl
          .formatMessage({ id: 'PAYFLOW_PAYMENT_TYPE_CARD', defaultMessage: 'card' })
          .toLowerCase();
  const autoPayDisclaimerId =
    paymentMethodType === 'bank'
      ? 'PAYFLOW_PAYMENT_AUTO_PAY_BANK_DISCLAIMER'
      : 'PAYFLOW_PAYMENT_AUTO_PAY_DISCLAIMER';
  const autoPayDisclaimerDefaultMessage = `By selecting <b>Pay</b>, I accept the <link1>Terms of Service</link1> and have read and acknowledge the <link2>Privacy Statement</link2>. I also allow Intuit to charge {2} to my {3} on {4} and periodically thereafter per my <link3>Billing Agreement</link3>. payments will be charged 3 days before the due date.`;
  const autoPayDisclaimer = dueDate ? (
    <FormattedMessage
      id={autoPayDisclaimerId}
      values={{
        2: displayAmount,
        3: paymentMethod,
        4: intl.formatDate(
          new Date(dueDate) < new Date() ? new Date() : new Date(dueDate.replace(/-/g, '/')),
          DateFormat_Long
        ),
        link1: (chunks: string) => (
          <a className="termsOfServiceFooter" href={tosUrl} target="_blank" rel="noreferrer">
            {chunks}
          </a>
        ),
        link2: (chunks: string) => (
          <a href={privacyUrl} target="_blank" rel="noreferrer">
            {chunks}
          </a>
        ),
        link3: (chunks: string) => (
          <a href="'/billing-agreement'" target="_blank">
            {chunks}
          </a>
        ),
        linebreak: <br />,
        b: (chunks: string) => <b>{chunks}</b>,
      }}
      defaultMessage={autoPayDisclaimerDefaultMessage}
    />
  ) : null;

  switch (paymentMethodType) {
    case 'bank':
      paymentMethodTypeDisclaimer = isAutoPayOn ? (
        autoPayDisclaimer
      ) : (
        <FormattedMessage
          id="PAYFLOW_PAYMENT_METHOD_BANK_DISCLAIMER"
          values={{
            1: privacyUrl,
            2: displayAmount,
            3: intl.locale !== 'fr' ? paymentMethod : '',
            4: intl.formatDate(new Date().getTime(), DateFormat_Long),
            5: '',
            link1: (chunks: string) => (
              <a className="termsOfServiceFooter" href={tosUrl} target="_blank" rel="noreferrer">
                {chunks}
              </a>
            ),
            link2: (chunks: string) => (
              <a href={privacyUrl} target="_blank" rel="noreferrer">
                {chunks}
              </a>
            ),
            linebreak: <br />,
            b: (chunks: string) => <b>{chunks}</b>,
          }}
          defaultMessage={`By selecting <b>Pay</b>, you accept the <link1>Terms of Service</link1>. Our <link2>Global Privacy Statement</link2> will apply to any personal information you provide us. You also allow Intuit to charge {2} to your {3} on {4}. {linebreak} You also provide written instructions for Intuit Inc. to obtain and periodically refresh information from bank verification data providers to validate your bank account information and for other fraud detection purposes.`}
        />
      );
      break;
    case 'eft':
      paymentMethodTypeDisclaimer = (
        <div>
          <FormattedMessage
            id="PAYFLOW_PAYMENT_METHOD_EFT_DISCLAIMER"
            values={{
              1: privacyUrl,
              2: displayAmount,
              3: intl.locale !== 'fr' ? paymentMethod : '',
              4: intl.formatDate(new Date().getTime(), DateFormat_Long),
              5: '',
              link1: (chunks: string) => (
                <a
                  className="termsOfServiceFooter"
                  href={caToSUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {chunks}
                </a>
              ),
              link2: (chunks: string) => (
                <a href={privacyUrl} target="_blank" rel="noreferrer">
                  {chunks}
                </a>
              ),
              linebreak: <br />,
              b: (chunks: string) => <b>{chunks}</b>,
            }}
            defaultMessage={`By selecting <b>Pay</b>, you accept the <link1>Terms of Service</link1> and have read and acknowledge the <link2>Privacy Statement</link2>. You also allow Intuit to charge {2} to your {3} on {4}.`}
          />
        </div>
      );
      break;
    case 'pbb':
      paymentMethodTypeDisclaimer = (
        <div>
          <FormattedMessage
            id="PAYFLOW_PAYMENT_METHOD_PBB_DISCLAIMER"
            values={{
              1: privacyUrl,
              2: displayAmount,
              3: intl.locale !== 'fr' ? paymentMethod : '',
              4: intl.formatDate(new Date().getTime(), DateFormat_Long),
              5: '',
              link1: (chunks: string) => (
                <a className="termsOfServiceFooter" href={tosUrl} target="_blank" rel="noreferrer">
                  {chunks}
                </a>
              ),
              link2: (chunks: string) => (
                <a href={privacyUrl} target="_blank" rel="noreferrer">
                  {chunks}
                </a>
              ),
              linebreak: <br />,
              b: (chunks: string) => <b>{chunks}</b>,
            }}
            defaultMessage={`By selecting <b>Continue</b>, you accept the <link1>Terms of Service</link1>. Our global <link2>Privacy Statement</link2> will apply to any personal information you provide us. You also allow Intuit to charge {2} to your {3} on {4}.`}
          />
        </div>
      );
      break;
    case 'dc,cc':
    case 'dc':
    case 'cc':
      paymentMethodTypeDisclaimer = isAutoPayOn ? (
        autoPayDisclaimer
      ) : (
        <FormattedMessage
          id="PAYFLOW_PAYMENT_METHOD_CC_DC_DISCLAIMER"
          values={{
            1: privacyUrl,
            2: displayAmount,
            3: intl.locale !== 'fr' ? paymentMethod : '',
            4: intl.formatDate(new Date().getTime(), DateFormat_Long),
            5: '',
            link1: (chunks: string) => (
              <a className="termsOfServiceFooter" href={tosUrl} target="_blank" rel="noreferrer">
                {chunks}
              </a>
            ),
            link2: (chunks: string) => (
              <a href={privacyUrl} target="_blank" rel="noreferrer">
                {chunks}
              </a>
            ),
            b: (chunks: string) => <b>{chunks}</b>,
          }}
          defaultMessage={`By selecting <b>Pay</b>, you accept the <link1>Terms of Service</link1>. Our <link2>Global Privacy Statement</link2> will apply to any personal information you provide us. You also allow Intuit to charge {2} to your {3} on {4}.`}
        />
      );
      break;
    case 'ap':
      paymentMethodTypeDisclaimer = (
        <FormattedMessage
          id="PAYMENT_METHOD_APPLE_PAY_DISCLAIMER"
          values={{
            2: displayAmount,
            3: intl.locale !== 'fr' ? paymentMethod : '',
            4: intl.formatDate(new Date().getTime(), DateFormat_Long),
            b: (chunks: string) => <b>{chunks}</b>,
            link1: (chunks: string) => (
              <a className="link" id="applePayTOS" href={tosUrl} target="_blank" rel="noreferrer">
                {chunks}
              </a>
            ),
            link2: (chunks: string) => (
              <a
                className="link"
                id="applePayPrivacyPolicy"
                href={privacyUrl}
                target="_blank"
                rel="noreferrer"
              >
                {chunks}
              </a>
            ),
          }}
          defaultMessage={`By selecting <b>Apple Pay</b>, you accept the <link1>Terms of Service</link1>. Our <link2>Global Privacy Statement</link2> will apply to any personal information you provide us. You also allow Intuit to charge {2} to your {3} on {4}.`}
        />
      );
      break;

    case 'pp':
      paymentMethodTypeDisclaimer = (
        <FormattedMessage
          id="PAYFLOW_PAYMENT_METHOD_BANK_DISCLAIMER_2"
          values={{
            a: (chunks: string) => (
              <a
                className="link"
                id="paypalAgreement\"
                href="https://www.paypal.com/us/webapps/mpp/ua/useragreement-full"
                target="_blank"
                rel="noreferrer"
              >
                {chunks}
              </a>
            ),
          }}
          defaultMessage={
            'Payments are processed by PayPal. Any payment you make with PayPal is subject to the <a>PayPal User Agreement</a> which will prevail over any contradictory terms.'
          }
        />
      );
      break;
  }

  return (
    <div className="message">
      <div className="cp-link payment-disclaimer">{paymentMethodTypeDisclaimer}</div>

      <style jsx>
        {`
          .message {
            font-family: AvenirNextforINTUIT-Regular;
            font-size: ${fontSize.xxs};
            color: ${colors.ghostGray};
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: -0.1px;
            user-select: none;

            .payment-disclaimer {
              margin: 0;
              margin-bottom: 10px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default PaymentDisclaimer;
