import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import FrictionlessLogin, { frictionlessLoginState } from './FrictionlessLogin';
import { FrictionlessLoginElevateOtp } from './FrictionlessLoginElevateOtp';
import { makeDebounce } from './debounce';
import { useReportFrictionlessEvent } from './useFrictionlessLoginLogs';

import { ERROR_FRICTIONLESS_LOGIN_OTP_REMEDIATION } from 'businessLogic/Wallet';
import useSessionStorage from 'hooks/useSessionStorage';
import SegmentIO from 'reporting/SegmentIO';
import {
  authTicketSelector,
  frictionlessLoginEmailSelector,
  frictionlessLoginSessionIdSelector,
  isFrictionlessLoginEnabledSelector,
} from 'store/auth/selectors';
import { checkFrictionlessLogin, refreshAuth, signIn } from 'store/auth/slice';
import { useSelector } from 'store/utils';
import { walletSelectors } from 'store/wallet/selectors';
import { fetchWallets } from 'store/wallet/slice';

export interface FrictionlessLoginContainerProps {}

const debounce = makeDebounce();

const FrictionlessLoginContainer: React.FC<FrictionlessLoginContainerProps> = () => {
  const [stepState, setStepState] = useState<frictionlessLoginState>('step-1');
  const dispatch = useDispatch();
  const isEnabled = useSelector(isFrictionlessLoginEnabledSelector);
  const email = useSelector(frictionlessLoginEmailSelector);
  const sessionId = useSelector(frictionlessLoginSessionIdSelector);
  const authTicket = useSelector(authTicketSelector);
  const fetchWalletErrorMessage = useSelector((s) =>
    walletSelectors.fetchWalletErrorMessage(s.wallet)
  );
  const [hasBeenShownForSession, setHasBeenShownForSession] = useSessionStorage(
    'frictionlessLoginIsShown',
    false
  );
  const frictionlessLogger = useReportFrictionlessEvent();

  const shouldHide = !isEnabled || !email || !sessionId;

  const logTokenFrictionlessEnabled = () => {
    // when the component init sessionId & email will always be empty, so we want to only log once the process is likely to be finished
    debounce(
      () =>
        frictionlessLogger.log({
          event: 'report',
          message: 'is-frictionless-enabled',
          extraParams: {
            isEnabled: Boolean(email && sessionId),
          },
        }),
      1000
    );
  };

  useEffect(() => {
    logTokenFrictionlessEnabled();
  }, [email, sessionId]);

  useEffect(() => {
    // @ts-expect-error
    dispatch(checkFrictionlessLogin());
    return () => {
      // Do not show more then once per session
      setHasBeenShownForSession(true);
    };
  }, []);

  useEffect(() => {
    if (authTicket) {
      // If user is logged in, don't show the modal after he logs out
      setHasBeenShownForSession(true);
    }
  }, [authTicket]);

  useEffect(() => {
    if (!shouldHide && !hasBeenShownForSession) {
      frictionlessLogger.log({
        message: 'frictionless-modal-displayed',
      });
    }
  }, [shouldHide, hasBeenShownForSession]);

  const handleContinueButtonClick = () => {
    SegmentIO.frictionlessLoginSubmit();
    setStepState('step-2');
  };

  const handleNotMeButtonClick = () => {
    SegmentIO.frictionlessLoginNotYou();
    frictionlessLogger.log({
      message: 'not-me-button-clicked',
      extraParams: {
        userClosedModal: true,
      },
    });
    // @ts-expect-error
    dispatch(signIn());
  };
  const handleHide = () => {
    SegmentIO.frictionlessLoginCancel();
    frictionlessLogger.log({
      message: 'user-closed-modal',
      extraParams: {
        userClosedModal: true,
      },
    });
    setStepState('cancelled');
  };
  const handleIdentityWidgetComplete = async () => {
    frictionlessLogger.log({
      message: 'frictionless-login-complete',
      event: 'done',
    });
    // @ts-ignore
    const auth = await dispatch(refreshAuth());
    SegmentIO.frictionlessLoginSuccess(auth.ticket);
    // @ts-ignore
    await dispatch(checkFrictionlessLogin());
    // @ts-ignore
    dispatch(fetchWallets());
  };
  const handleIdentityWidgetError = (error: Error) => {
    SegmentIO.frictionlessLoginError();
    frictionlessLogger.log({
      logLevel: 'error',
      error,
      message: 'frictionless-login-identity-widget-error',
    });
  };

  const handleIdentityWidgetReady = (startedAt: Date) => {
    const end = Date.now();
    const executionTimeMs = Date.now() - startedAt.getTime();
    frictionlessLogger.log({
      message: 'frictionless-login-identity-widget-ready',
      event: 'ready',
      rum: {
        executionTimeMs,
        end,
        start: startedAt.getTime(),
      },
    });
  };

  if (fetchWalletErrorMessage === ERROR_FRICTIONLESS_LOGIN_OTP_REMEDIATION) {
    return (
      <FrictionlessLoginElevateOtp
        onComplete={handleIdentityWidgetComplete}
        onError={handleIdentityWidgetError}
      />
    );
  }

  if (hasBeenShownForSession) {
    // Modal is shown only once per session
    return null;
  }
  if (shouldHide) {
    return null;
  }

  return (
    <FrictionlessLogin
      email={email}
      sessionId={sessionId}
      stepState={stepState}
      onContinueButtonClick={handleContinueButtonClick}
      onComplete={handleIdentityWidgetComplete}
      onError={handleIdentityWidgetError}
      onReady={handleIdentityWidgetReady}
      onHide={handleHide}
      onNotMeButtonClick={handleNotMeButtonClick}
    />
  );
};

export default FrictionlessLoginContainer;
